/* Universal Styles */

html {
  font-size: 18px;
}

body {
  padding-top: 55px;
  background-color: #F0F0F0 !important;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}

h1 {
  padding: 0;
  margin: 0 !important;
  text-align: center;
  font-family: Pacifico, sans-serif;
  font-size: 2rem !important;
  color: #fff;
}

.navbar {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.header-icon {
  color: #FFF;
  font-size: 30px;
  line-height: 30px;
  margin: 0 10px;
}

.header-icon:hover {
  cursor: pointer;
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #222; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  position: relative;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}

#welcome {
  text-align: center;
}

#welcome h2 {
  font-family: Pacifico, sans-serif;
  color: #28a745;
}
