.SearchBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  padding: 0 20px 20px 20px;
}

.SearchBar-sort-options ul {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  color: #ffffff;
}

.SearchBar-sort-options li {
  cursor: pointer;
  width: 50%;
  border-bottom: 1px solid #fff;
  padding: 20px 2.58rem .33rem 2.58rem;
  line-height: 1.13;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  transition: color .25s;
}

.SearchBar-sort-options li:hover {
  color: #d4cfcf;
}

.SearchBar-sort-options li.active,
.SearchBar-sort-options li.active:hover {
  border-bottom: 1px solid #f0c36c;
  color: #f0c36c;
}

.SearchBar-fields {
  margin-bottom: 2.4rem;
}

.SearchBar-submit {
  text-align: center;
}

.SearchBar-submit a {
  transition: background-color .5s;
  margin: 0 5px;
  font-size: 1rem;
  text-align: center;
  color: #FFF;
}

@media only screen and (max-width: 560px) {

  .SearchBar-sort-options ul {
    margin-bottom: 0 0 2rem 0;
  }

  .SearchBar-fields {
    flex-direction: column;
    align-items: center;
  }

  .SearchBar-fields input {
    margin-right: 0;
    margin-bottom: .86rem;
  }
}