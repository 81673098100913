.Business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 .25rem 1rem .25rem;
  background: #FFF;
  border: 1px solid #CCC;
  border-radius: 6px;
}

.Business .image-container {
  margin-bottom: 1rem;
  border-radius: 6px 6px 0 0;
}

.Business h2 {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.Business-information {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

.Business-information p {
  font-size: .88rem;
  font-weight: 300;
  line-height: 1rem;
}

.Business-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Business-reviews {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  padding-right: 15px;
}

.Business-reviews h3 {
  color: #CCA353;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}

.Business-reviews .rating {
  font-size: .88rem;
  line-height: 1rem;
}